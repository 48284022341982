import CategoryHead from "@/components/categoryHead"
import { FC, useCallback, useEffect, useState } from "react"
import styles from "./styles/message.module.css"
import Footer from "@/components/footer"
import Back from "@/components/back"
import useAxios from "@/hooks/useAxios"
import useUser from "@/hooks/useUser"
import Empty from "@/components/empty"
import useRoleAuth from "@/hooks/useRoleAuth"
import Pagination from "@/components/pagination";
import u, { cancelAllPreviousFetchRequests } from "@/util"
import Img from "@/components/img"
import { appendHost } from "@/const/append";
import Button from "@/components/button"

const UserMessage: FC = () => {
    useRoleAuth()
    const [user] = useUser()
    const { req } = useAxios("message/list", "post");
    const { req : reqUpdate} = useAxios("message/update", "post");

    const [data, setData] = useState<any>()
    const fetchDetails = useCallback(async (page?: any) => {
        if (!user?.token) return;
        const res = await req({
            token: user?.token,
            page: page || 1
        });

        console.log('list message  ', res)
        if (res?.data?.messages?.total > 0) {
            callUpdate();
            setData(res?.data?.messages)
        }
    }, [user])


    useEffect(() => {
        fetchDetails()
    }, [fetchDetails])

    const callUpdate = useCallback(async (id?: any) => {
        if (!user?.token) return;
        const res = await reqUpdate({
            token: user?.token
        });

        console.log('callUpdate message  ', res)
        if (res?.data?.messages?.total > 0) {
            setData(res?.data?.messages)
        }
    }, [user])

    const routeToDetail = useCallback(async (id: any) => {
        return u.open("/user/messagedetail/"+id)
    }, [])

    const handleJump = useCallback((code: any) => {
        if(code){
            return u.open("/user/redeem?code="+code);
        }else{
            return;
        }
    }, [])

    return (
        <CategoryHead>
            <div className="plr mh700 mt80">
                <div className="mw1100 mb10">
                    <Back>消息中心</Back>
                    {
                       data && Array.isArray(data.data) && data.data.length > 0 && <>
                        <div className={styles.up}>消息</div>
                       {data.data.map((x: any, i: any) => {
                            return <div key={i}>
                                <div className={styles.wrapper} 
                                onClick={() => {
                                    routeToDetail(x?.id)
                                }}>
                                    <div className={styles.contentwrapper}>
                                        <Img src={appendHost("/images/xiaoxi.png")} width={50} height={50} cls={styles.img}/>
                                        <div className={styles.content}>
                                            <div className={styles.title}>系统消息</div>
                                            <div className={styles.desc} style={{whiteSpace: "pre-wrap"}} dangerouslySetInnerHTML={{ __html:x.message}}></div>
                                            {/* {!!x.url && <>
                                                <Button event={()=>{handleJump(x.url)}} cls={`btn_sty2 ${u.themeBtnCls()} width100`}>点击兑换</Button>
                                            </>} */}
                                            {x.type && x.type === 2 && <>
                                                <Button event={()=>{handleJump(x.code)}} cls={`btn_sty2 ${u.themeBtnCls()} width100`}>点击兑换</Button>
                                            </>}
                                        </div>
                                        <div className={styles.time}>{u.fmtDateSince(x?.insert_time * 1000)}</div>
                                    </div>
                                </div>
                            </div>
                        })}
                        <div>&nbsp;</div>
                              <Pagination cancelFn={cancelAllPreviousFetchRequests} lastPg={data?.last_page} total={data?.total} changeHandler={ async (e) => {
                                    await fetchDetails(e.current);
                                }} />
                       </>
                    }
                    {!data && <Empty></Empty>}
                </div>
            </div>
            <Footer />
        </CategoryHead>
    )
}
export default UserMessage